import React, { useRef, useState } from "react";
import Switch from "react-switch";

import { Row, Col, Popover, PopoverBody, Button, Modal, ModalBody } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import { useHistory } from "react-router-dom";
import DragVerticalIcon from "mdi-react/DragVerticalIcon";

import { useDrag, useDrop } from 'react-dnd'
// const style = {
//   backgroundColor: 'white',
//   cursor: 'move',
// }

const RenderSubscription = ({ result, row_item, loadingpopular, showActive, loadingItem, toggleStatus, updatePopular, updateSortBy, index, moveCard, id }) => {

    const ref = useRef(null)

    const [enablePopOver, setEnablePopOver] = useState(false);

    const togglePopOver = () => {
        setEnablePopOver(!enablePopOver)
    };

    const [{ handlerId }, drop] = useDrop({
        accept: "card",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        canDrop(item, monitor) {
            if (row_item.title_id == item.title_id) {
                return true
            } else {
                return false
            }
        },
        hover(item, monitor) {

            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            // console.log(drop, "hover")
            // console.log(id, "id")
            // console.log(row_item, "title_id")
            const hoverIndex = index
            // Don't replace items differenct title id
            if (row_item.title_id != item.title_id) {
                // console.log("")
                return
            }
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex, row_item.title_id)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
        drop(item, monitor) {

            moveCard(0, 0, row_item.title_id, true)
           
        },
    })
    const [{ isDragging }, drag, preview] = useDrag({
        type: "card",
        item: () => {
            return { id, index, title_id: row_item.title_id }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1
    preview(drop(ref))
    drag(drop(ref))

    if (row_item.static) {
        // console.log(row_item.title, "result title")
        // console.log(result.length, "result")
    }
    


    const history = useHistory();

    return (
        <tr ref={ref} key={handlerId} test={id} style={{ cursor : !row_item.static ? 'move' : 'unset', opacity }} data-handler-id={handlerId}>
            <React.Fragment>
                <td className="title" style={{ background: '#f9fafe', minWidth: " 220px" }}>
                    {
                        <div style={{ display: 'flex' }}>
                            {!row_item.static &&
                                <div className='handle'>
                                    <DragVerticalIcon />
                                </div>
                            }
                            
                            <h5 className="featureName">
                                {row_item.title}
                                {!row_item.static &&
                                    <i
                                        class="fa fa-info-circle ml-1"
                                        id={`features-${row_item._id}`}
                                        onClick={() => {
                                            togglePopOver();
                                        }
                                        }
                                        aria-hidden="true"
                                    ></i>
                                }
                            </h5>
                            
                            {!row_item.static && 
                                <Popover
                                    placement="right"
                                    // isOpen={popoverState[item.feature_id]}
                                    isOpen={enablePopOver}
                                    target={`features-${row_item._id}`}
                                    // toggle={() => setPopoverState(prev => {
                                    //     return { [item.feature_id]: !prev[item.feature_id] }
                                    // })}
                                    toggle={() => { togglePopOver() }}
                                >
                                    <PopoverBody className="py-3">
                                        <textarea
                                            className="form-control textAreaa"
                                            defaultValue={row_item?.description}
                                            name="cloud_based_desc"
                                            placeholder="description"
                                            rows={4}
                                            cols={60}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                // let temp = { ...featureDescArr[0] }
                                                // temp.cloud_based_desc = e.target.value
                                                // setFeatureDescArr([{ ...temp }])
                                            }}
                                        />
                                        <div className="d-flex justify-content-between mt-3">
                                            <Button
                                                style={{ width: '45%' }}
                                                color="grey"
                                                onClick={() => {
                                                    togglePopOver();
                                                    // setPopoverState(prev => {
                                                    //     return { [item.feature_id]: !prev[item.feature_id] }
                                                    // })
                                                }}>Cancel
                                            </Button>
                                            <Button
                                                style={{ width: '45%' }}
                                                // onClick={(e) => saveFeaturesDesc(item, featureDescArr[0].cloud_based_desc)}
                                            >Save</Button>
                                        </div>
                                    </PopoverBody>
                                </Popover>
                            }
                        </div>
                    }
                </td>
                {
                    result && result.length > 0 && result.map((item, index) => {

                        // console.log(row_item.title, "row_item.title")
                        // console.log(item.descriptive_features, "row_item.title")

                        if (showActive ? item.status === 1 : true) {
                            if (row_item.feature_id == "title") {
                                return (
                                    <td style={{ minWidth: " 220px" }}>
                                                    
                                        {
                                            <div style={{ display: 'block' }}>
                                                <div>
                                                    <span>
                                                        {item.title}
                                                        {item.is_trial &&
                                                            <span style={{ marginLeft: 10, fontSize: 8, backgroundColor: "orange", padding: "1px 4px", border: "1px solid orange", borderRadius: 8, color: "#fff" }}>Trial</span>
                                                        }
                                                    </span>

                                                    <span style={{ marginRight: "10px", float : 'right', right: 0, fontSize: "0.75rem", color: "black" }} onClick={() => {
                                                        history.push(`${process.env.PUBLIC_URL}/subscriptions/edit/${item._id}`);
                                                    }} >
                                                    
                                                        <i className="fa fa-edit"></i>
                                                    </span>
                                                </div>
                                                {
                                                    <div style={{ marginTop: 6, display: 'flex', justifyContent: "space-between" }}>
                                                        <span>Activate / Deactivate</span>
                                                        {/* <Col className="dataCell" style={{ height: "50px" }}> */}
                                                        {loadingItem && loadingItem == item._id ?
                                                            <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                            :
                                                            <Switch
                                                                onChange={() => toggleStatus(item, index)}
                                                                checked={item.status == 2 ? false : true}
                                                                className="react-switch"
                                                                disabled={item?.is_free === true}
                                                                height={20}
                                                                width={40}
                                                            />

                                                        }
                                                        {/* </Col> */}
                                                    </div>
                                                }
                                                <div style={{ marginTop: 6, display: 'flex' }}>
                                                    {loadingpopular && loadingpopular == item._id ?
                                                        <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                        :
                                                        <>
                                                                        
                                                            {true &&
                                                                <div
                                                                    
                                                                    onClick={() => {
                                                                        updatePopular(item, { checked: !item.popular })
                                                                    }
                                                                    }
                                                                
                                                                    style={{
                                                                        // marginLeft: "10px",
                                                                        background: item.popular ? "#FED3A1" : 'gray',
                                                                        color: item.popular ? "#313131" : '#fff',
                                                                        padding: "2px 12px",
                                                                        borderRadius: "10px",
                                                                        fontSize: "9px",
                                                                        textTransform: "uppercase",
                                                                        fontWeight: "bold",
                                                                        display: "flex",
                                                                        alignSelf: "center",
                                                                        // marginLeft: 10,
                                                                    }}>Most Popular</div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </td>
                                )
                            } else if (row_item.feature_id == "sub_title") {
                                return <td className="" style={{ height: "80px", overflow: "auto" }} >{item.description}</td>
                            } else if (row_item.feature_id == "fees") {
                                return <td className="" style={{ height: "80px", overflow: "auto" }} >{item.fees}</td>
                            } else if (row_item.feature_id == "promo_details") {
                                return <td className="" style={{ height: "50px" }}>
                                    {item.promo_rate > 0 ?
                                        <span>Fees : {item.promo_rate} {item.promo_rate_type == 2 ? '%' : ''}<br />
                                            End Date : {moment(item.promo_end_date).format("YYYY-MM-DD")}</span>
                                        :
                                        <span>-</span>
                                                
                                    }
                                </td>
                            } else if (row_item.feature_id == "sales_per_day") {
                                return <td className="" style={{ height: "50px" }}>{item.unlimited_sales ? `Unlimited` : item.sales_per_day}</td>
                            } else if (row_item.feature_id == "number_of_registers") {
                                return <td className="" style={{ height: "50px" }}>{item.unlimited_registration ? `Unlimited` : item.number_of_registers}</td>
                            } else if (row_item.feature_id == "number_of_users") {
                                return <td className="" style={{ height: "50px" }}>{item.unlimited_users ? `Unlimited` : item.number_of_users}</td>
                            } else if (row_item.feature_id == "guest_visitor_registry") {
                                return <td className="" style={{ height: "60px" }}>{item.unlimited_vregistries ? `Unlimited` : item.number_of_vregistries}</td>
                            } else if (row_item.feature_id == "linky_scan_id") {
                                return <td className="" style={{ height: "50px" }}>{item.unlimited_linkyScan ? `Unlimited` : item.number_of_linkyScan}</td>
                            } else if (row_item.feature_id == "ecommerce_website") {
                                return <td className="" style={{ height: "70px" }}>{item.online_orders ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "bookings_reservations") {
                                return <td className="" style={{ height: "60px" }}>{item.table_reservation ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "function_booking") {
                                return <td className="" style={{ height: "50px" }}>{item.function_booking ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "offers_vouchers") {
                                return <td className="" style={{ height: "50px" }}>{item.offers_vouchers ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "loyalty_cards") {
                                return <td className="" style={{ height: "50px" }}>{item.loyalty_cards ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "payment_links") {
                                return <td className="" style={{ height: "50px" }}>{item.payment_links ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "price_list") {
                                return <td className="" style={{ height: "50px" }}>{item.price_list ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "remove_logo") {
                                return <td className="" style={{ height: "50px" }}>{item.remove_logo ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "tips_reviews") {
                                return <td className="" style={{ height: "50px" }}>{item.tips_reviews ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "call_waiter") {
                                return <td className="" style={{ height: "50px" }}>{item.call_waiter ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "clean_table") {
                                return <td className="" style={{ height: "50px" }}>{item.clean_table ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "customize_themes_fonts") {
                                return <td className="" style={{ height: "50px" }}>{item.customize_themes_fonts ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "review_booster") {
                                return <td className="" style={{ height: "50px" }}>{item.review_booster ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "linky_meet") {
                                return <td className="" style={{ height: "50px" }}>{item.linky_meet ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "event_tickets") {
                                return <td className="" style={{ height: "50px" }}>{item.sell_ticket ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "xero_integration") {
                                return <td className="" style={{ height: "50px" }}>{item.xero_integration ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "invoice_quote") {
                                return <td className="" style={{ height: "50px" }}>{item.invoice_quote ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "quick_sms") {
                                return <td className="" style={{ height: "50px" }}>{item.quick_sms ? `Yes` : "No"}</td>
                            } else if (row_item.feature_id == "sort_by") {
                                return <td className="" style={{ height: "50px", alignItems: "center" }} >
                                    <input style={{ width: "40px", height: "30px", marginLeft: "3px" }} defaultValue={item.sort_by} name="sort_by" id={`sort_${index}`} type="number"
                                        onChange={(e) => {
                                            let sort_no = e.target.value;
                                            if (e.target.value) {
                                                updateSortBy(item, sort_no);
                                            }
                                        }} />
                                </td>
                            } else if (item.descriptive_features && item.descriptive_features.length > 0 && item.descriptive_features.includes(row_item.title)) {
                                return <td className="" style={{ height: "50px" }}>Yes</td>
                            }
                                            
                            else {
                                return (
                                    <td>
                                        -
                                    </td>
                                )
                                                
                            }
                        }
                    })
                }
            </React.Fragment>
        </tr>
    )
}

export default RenderSubscription
