import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form'
import Breadcrumb from '../../components/common/breadcrumb';
import "./style.css"
import { Link, useHistory } from "react-router-dom";
import { Button, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from "moment";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import HashLoader from "react-spinners/HashLoader";

let DatePickerInput = ({ value, onClick }) => {
  return (
    <Input type="text" value={value} onFocus={onClick} onClick={onClick} />
  );
}

const TooltipForm = () => {

  const history = useHistory();

  let [loading, setLoading] = useState(true);
  let [loadingbtn, setloadingbtn] = useState(false);
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  let [showerrorstatus, setshowerrorstatus] = useState(false);
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const [validateClass, setValidateClass] = useState(false);
  const [nor_readonly, setnor_readonly] = useState(false);
  const [nou_readonly, setnou_readonly] = useState(false);
  const [spd_readonly, setspd_readonly] = useState(false);
  const [rgst_readonly, setRgst_readonly] = useState(false);
  const [ls_readonly, setLs_readonly] = useState(false)
  const [fees, setfees] = useState(0)
  const [promo_rate_type, setpromo_rate_type] = useState(1)
  const [is_trial, setis_trial] = useState("paid");
  const [disable_trial, setdisable_trial] = useState(false);
  const [features, setfeatures] = useState([]);

  useEffect(() => {

    client.get(api.fetch_subscription, { trial: true }, async function (error, response) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              if (response.result.length > 0) {
                // alert("here")
                setdisable_trial(true)
              }
              else {
                setdisable_trial(false)
              }
              // setResult(response.result)
            } else {
            }
          } catch (e) {
          }
        } else {
        }
      } else {
      }

      setLoading(false);
      fetchfeatures();
    });


  }, [])

  const fetchfeatures = () => {

    client.get(api.fetch_features, { activeall: true }, async function (error, response) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              setfeatures(response.result)
            } else {
            }
          } catch (e) {
          }
        } else {
        }
      } else {
      }

      setLoading(false);
    });

  }

  const onSubmit = data => {

    // console.log(data, "form data===>")

    if (data != '') {
      if (data.description.length <= 50) {
        // if (!moment(start_date).isValid()) {
        //   return false;
        // }
        // console.log(data.promo_rate, "data.promo_rate")
        if (data.promo_rate > 0 && !moment(end_date).isValid()) {
          setshowerrorstatus(true)
          return false;
        }

        data.start_date = start_date;
        data.end_date = end_date;
        data.is_trial = is_trial === "trial" ? true : false;
        data.is_free = is_trial === "free" ? true : false;

        setloadingbtn(true);

        client.post(api.create_subscription, data, async function (error, response) {
          if (!error) {
            if (typeof response.error !== "undefined") {
              try {
                if (!response.error) {
                  history.push('/subscriptions');
                  toast.success("Added Successfully");
                } else {
                  toast.error(response.message);
                }
              } catch (e) {
                toast.error("Internal error occured. Please contact support");
              }
            } else {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }

          setloadingbtn(false);
        });
      } else {
        toast.error("Description max limit 50 characters")
      }

    } else {
      setshowerrorstatus(true)
      errors.showMessages();
    }

  };

  // console.log(getValues(), "parseFloat(getValues('fee'))") 

  return (
    <>
      <Breadcrumb title="Add subscription" parent="Home" />

      <div className="form-container" >

        {loading ?
          <div className="d-flex justify-content-center mt-4" style={{ display: "flex", alignItems: "center", height: window.innerHeight }}>
            <HashLoader color="#36D7B7" loading={true} />
          </div>
          :
          <form className="needs-validation add-subscription" noValidate="" onSubmit={handleSubmit(onSubmit)}>
            <div className="card countryDetails">
              {/* <div className="card-header b-l-primary">
              <h3>subscription </h3>
            </div> */}
              <div className="card-body">
                <div>
                  {!disable_trial &&
                    <div className="form-group">
                      <label htmlFor="">Type of plan</label>
                      <div className="col-md-12 mb-12 row">

                        <div className="form-check">
                          <div className="radio p-0">
                            <input onChange={(event) => {
                              setis_trial('trial')
                              setValue('fee', '0')
                              setValue('promo_rate', '0')

                            }} name="is_trial" value="trial" id="radio_trial" type="radio" ref={register({ required: false })} />
                            <label htmlFor="radio_trial">Trial</label>
                          </div>
                        </div>

                        <div className="form-check">
                          <div className="radio p-0">
                            <input onChange={(event) => {
                              setis_trial('paid')

                            }} name="is_trial" value="paid" defaultChecked={true} id="radio_paid" type="radio" ref={register({ required: false })} />
                            <label htmlFor="radio_paid">Paid</label>
                          </div>
                        </div>

                        <div className="form-check">
                          <div className="radio p-0">
                            <input onChange={(event) => {
                              setis_trial('free')
                              setValue('fee', '0')
                              setValue('title', 'Forever Free Plan')
                            }} name="is_trial" value="free" id="radio_free" type="radio" ref={register({ required: false })} />
                            <label htmlFor="radio_free">Forever Free</label>
                          </div>
                        </div>

                      </div>
                    </div>
                  }

                  <div className="form-group form-row">
                    <div className="col-md-6 mb-6">
                      <label htmlFor="">Title Of Subscription</label>
                      <input className="form-control" name="title" disabled={is_trial === "free" ? true : false} type="text" ref={register({ required: true })} />
                      <span>{errors.title && 'This field is required'}</span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label htmlFor="">Sub Title</label>
                      <input className="form-control" name="description" type="text"
                        ref={register({ required: 'This field is required', maxLength: 50 })} />

                      {/* <textarea className="form-control textarea" name="description" rows="3" cols="50" placeholder="" ref={register({ required: true })}></textarea> */}
                      {/* <span style={{ color: '#ff5370' }}>{errors.description && 'This field is required'}</span> */}
                      <span style={{ color: '#ff5370' }}>{errors?.description?.message && errors?.description?.message}</span>
                      {errors.description?.type == 'maxLength' && <span style={{ color: '#ff5370' }}>Subtitle should be less than 50 chars</span>}
                      {/* <div className="valid-feedback">Looks good!</div> */}
                    </div>


                  </div>
                  <div className="form-group form-row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="">Subscription Fee</label>
                      <input onChange={event => {
                        setfees(event.target.value)
                      }} className="form-control" id="" step={0.01} disabled={is_trial === "trial" || is_trial === "free" ? true : false} name="fee" onWheel={() => document.activeElement.blur()} type="number" ref={register({ required: true })} />
                      <span>{errors.fee && 'This field is required'}</span>
                      {/* <div className="invalid-feedback">Please provide a valid city.</div> */}
                    </div>
                    {is_trial !== "free" &&
                      <>
                        <div className="col-md-2 mb-2">
                          <label htmlFor="">Promotional Rate Type</label>
                          <select
                            onChange={event => {
                              // console.log(event.target.selected, "event.target.selected")
                              // console.log(event.target.value, "event.target.value")
                              setpromo_rate_type(event.target.value)
                            }}
                            className="form-control" name="promo_rate_type" size="1" ref={register({ required: true })}>
                            <option value="1" selected>Amount Based</option>
                            <option value="2">Percentage Based</option>
                          </select>
                          {/* <input className="form-control" id="" step={0.01} disabled={is_trial === "trial" ? true : false} name="promo_rate" onWheel={() => document.activeElement.blur()} type="number" ref={register({
                            required: false,
                            max: parseFloat(fees),
                          })} /> */}
                          {/* <span>{errors.promo_rate && 'Value can not be higher than Subscription Fee'}</span> */}
                        </div>
                        <div className="col-md-2 mb-2">
                          <label htmlFor="">Promotional Rate</label>
                        <input className="form-control" id="" step={0.01} disabled={is_trial === "trial" ? true : false} name="promo_rate" onWheel={() => document.activeElement.blur()} type="number" ref={register({
                          required: false,
                          min: {
                            value: 0,
                            message: "Promotional rate cannot be negative"
                          },
                          max: {
                            value: promo_rate_type == 1 ? parseFloat(fees) : 100,
                            message: promo_rate_type == 1 ? "Promotional rate cannot pass Subscription Fee amount" : "Percentage discount should be between 0-100"
                          }
                        })} />
                        <span>{errors.promo_rate?.message
                        //  && (promo_rate_type == 1 ? 'Promotional rate cannot pass Subscription Fee amount' : 'Percentage discount should be between 0-100')
                        }</span>
                        </div>
                        {/* // }
                    // {is_trial !== "free" && */}
                        <div className="col-md-2 mb-2">
                          <label htmlFor="validationTooltip04">Start Date</label>
                          <DatePicker
                            customInput={<DatePickerInput />}
                            selected={start_date}
                            minDate={new Date()}
                            onChange={date => setStartDate(date)
                            }
                          />
                          <span style={{ color: '#ff5370' }}>{(showerrorstatus == true && !moment(start_date).isValid()) ? 'Select valid date' : ""}</span>
                        </div>
                        {/* // }
                    // {!(is_trial === "trial" || is_trial === "free" ? true : false) && */}
                        <div className="col-md-2 mb-2">
                          <label htmlFor="validationTooltip05">End Date</label>
                          <DatePicker
                            customInput={<DatePickerInput />}
                            selected={end_date}
                            minDate={start_date ? start_date : new Date()}
                            onChange={date => setEndDate(date)
                            }
                          />
                          <span style={{ color: '#ff5370' }}>{(showerrorstatus == true && !moment(end_date).isValid()) ? 'Select valid date' : ""}</span>
                        </div>
                      </>
                    }
                  </div>

                  {(is_trial === "trial" ? true : false) &&
                    <div className="form-group form-row">
                      <div className="col-md-3 mb-3">
                        <label htmlFor="">Trial Period</label>
                        <input className="form-control" id="" name="trial_period" onWheel={() => document.activeElement.blur()} type="number" ref={register({ required: true })} />
                        <span>{errors.trial_period && 'This field is required'}</span>
                        {/* <div className="invalid-feedback">Please provide a valid city.</div> */}
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="">Period Type</label>
                        <select className="form-control" name="period_type" size="1" ref={register({ required: true })}>
                          <option value="1">Days</option>
                          <option value="3">Weeks</option>
                          <option value="2">Months</option>
                        </select>
                      </div>
                    </div>
                  }

                  <h5>Choose Features</h5>
                  <div className="form-group form-row">
                    <div className="col-4 mb-3 d-flex align-items-center">
                      <div>
                        <label htmlFor="">Sales Per Day</label>
                        <input readOnly={spd_readonly} className="form-control" name="sales_per_day" onWheel={() => document.activeElement.blur()} type="number" ref={register({
                          required: "This field is required", valueAsNumber: true,
                          pattern: {
                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            message: "Number of Sales Per Day should be positive"
                          }
                        })} />
                        <span>{errors.sales_per_day?.message}</span>
                      </div>
                      <div>
                        <label htmlFor=""></label>
                        <div className="form-check">
                          <div className="checkbox p-0">
                            <input className="form-check-input" name="unlimited_sales_per_day" onChange={() => {
                              setValue('sales_per_day', '0')
                              setspd_readonly(!spd_readonly)

                            }} value={true} id="check_sales_per_day" type="checkbox" ref={register({ required: false })} />
                            <label className="form-check-label" htmlFor="check_sales_per_day">Unlimited</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 mb-3 d-flex align-items-center">
                      <div>
                        <label htmlFor="">Number Of Registers</label>
                        <input readOnly={nor_readonly} className="form-control" name="number_of_registers" onWheel={() => document.activeElement.blur()} type="number" ref={register({
                          required: "This field is required", valueAsNumber: true,
                          pattern: {
                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            message: "Number of registers should be positive"
      
                          }
                        })} />
                        <span>{errors.number_of_registers?.message}</span>
                      </div>
                      <div>
                        <label htmlFor=""></label>
                        <div className="form-check">
                          <div className="checkbox p-0">
                            <input className="form-check-input" onChange={() => {
                              setValue('number_of_registers', '0')
                              setnor_readonly(!nor_readonly)
                            }} name="unlimited_number_of_registers" id="check_number_of_registers" type="checkbox" ref={register({ required: false })} />
                            <label className="form-check-label" htmlFor="check_number_of_registers">Unlimited</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mb-3 d-flex align-items-center" >
                      <div>
                        <label htmlFor="">Number Of Users</label>
                        <input readOnly={nou_readonly} className="form-control" name="number_of_users" onWheel={() => document.activeElement.blur()} type="number" ref={register({
                          required: "This field is required", valueAsNumber: true,
                          pattern: {
                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            message: "Number Of Users should be positive"
      
                          }
                        })} />
                        <span>{errors.number_of_users?.message}</span>
                      </div>
                      <div>
                        <label htmlFor=""></label>
                        <div className="form-check">
                          <div className="checkbox p-0">
                            <input className="form-check-input" onChange={() => {
                              setValue('number_of_users', '0')
                              setnou_readonly(!nou_readonly)
                            }} name="unlimited_number_of_users" id="check_number_of_users" type="checkbox" ref={register({ required: false })} />
                            <label className="form-check-label" htmlFor="check_number_of_users">Unlimited</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 mb-3 d-flex align-items-center">
                      <div>
                        <label htmlFor="">Check-in registry per day</label>
                        <input readOnly={rgst_readonly} className="form-control" name="number_of_vregistries" onWheel={() => document.activeElement.blur()} type="number" ref={register({
                          required: "This field is required", valueAsNumber: true,
                          pattern: {
                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            message: "Number of check-in registry per day should be positive"
      
                          }
                        })} />
                        <span>{errors.number_of_vregistries?.message}</span>
                      </div>
                      <div>
                        <label htmlFor=""></label>
                        <div className="form-check">
                          <div className="checkbox p-0">
                            <input className="form-check-input" onChange={() => {
                              setValue('number_of_vregistries', '0')
                              setRgst_readonly(!rgst_readonly)
                            }} name="unlimited_vregistries" id="check_number_of_vregistries" type="checkbox" ref={register({ required: false })} />
                            <label className="form-check-label" htmlFor="check_number_of_vregistries">Unlimited</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mb-3 d-flex align-items-center">
                      <div>
                        <label htmlFor="">Linky Scan</label>
                        <input readOnly={ls_readonly} className="form-control" name="number_of_linkyScan" onWheel={() => document.activeElement.blur()} type="number" ref={register({
                          required: "This field is required", valueAsNumber: true,
                          pattern: {
                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            message: "Number of scan should be positive"
      
                          }
                        })} />
                        <span>{errors.number_of_linkyScan?.message}</span>
                      </div>
                      {/* <div>
                        <label htmlFor=""></label>
                        <div className="form-check">
                          <div className="checkbox p-0">
                            <input className="form-check-input" onChange={() => {
                              setValue('number_of_linkyScan', '0')
                              setLs_readonly(!ls_readonly)
                            }} name="unlimited_linkyScan" id="check_number_of_linkyScan" type="checkbox" ref={register({ required: false })} />
                            <label className="form-check-label" htmlFor="check_number_of_linkyScan">Unlimited</label>
                          </div>
                        </div>
                      </div> */}
                    </div>

                  </div>
                  {/* <div className="form-group">
                  <div className="col-md-12 mb-12 row">
                    <div className="form-check">
                      <div className="checkbox p-0">
                        <input className="form-check-input" name="is_trial" id="is_trial" type="checkbox" ref={register({ required: false })}/>
                        <label className="form-check-label" htmlFor="is_trial">Is Trial</label>
                      </div>
                    </div>
                  </div>
                </div> */}
                  <div className="form-group">
                    <div className="col-md-12 mb-12 row">
                      {/* <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="multiuser" id="checkbox_multiuser" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_multiuser">Multiuser</label>
                        </div>
                      </div> */}
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="online_orders" id="checkbox_online_orders" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_online_orders">
                            Online Ordering System
                          </label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="table_reservation" id="checkbox_table_reservation" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_table_reservation">
                            {/* Table Reservation */}
                            Bookings & Reservations
                          </label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="offers_vouchers" id="checkbox_offers_vouchers" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_offers_vouchers">Offers & vouchers</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="loyalty_cards" id="checkbox_loyalty_cards" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_loyalty_cards">Loyalty Cards</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="payment_links" id="checkbox_payment_links" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_payment_links">Payment Links</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="invoice_quote" id="checkbox_invoice_quote" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_invoice_quote">Invoices & Quotes</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="price_list" id="checkbox_price_list" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_price_list">Price list</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="remove_logo" id="checkbox_remove_logo" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_remove_logo">Remove the Linky logo</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="tips_reviews" id="checkbox_tips_reviews" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_tips_reviews">Tips & Reviews</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="call_waiter" id="checkbox_call_waiter" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_call_waiter">Call Waiter</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="clean_table" id="checkbox_clean_table" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_clean_table">Clean Table</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="customize_themes_fonts" id="checkbox_customize_themes_fonts" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="checkbox_customize_themes_fonts">Customizable themes & fonts</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="sell_ticket" id="sell_ticket" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="sell_ticket">Events & Tickets</label>
                        </div>
                      </div>

                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="linky_meet" id="linky_meet" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="linky_meet">Linky Meet</label>
                        </div>
                      </div>


                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input className="form-check-input" name="review_booster" id="review_booster" type="checkbox" ref={register({ required: false })} />
                          <label className="form-check-label" htmlFor="review_booster">Review Booster</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input
                            className="form-check-input"
                            name="xero_integration"
                            id="xero_integration"
                            type="checkbox"
                            ref={register({ required: false })}
                          />
                          <label className="form-check-label" htmlFor="xero_integration">Xero integration</label>
                        </div>
                      </div>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <input
                            className="form-check-input"
                            name="quick_sms"
                            id="quick_sms"
                            type="checkbox"
                            ref={register({ required: false })}
                          />
                          <label className="form-check-label" htmlFor="quick_sms">Quick SMS</label>
                        </div>
                      </div>

                      {/* <div className="form-check">
                      <div className="checkbox p-0">
                        <input className="form-check-input" name="modifiers" value={true} id="checkbox_modifiers" type="checkbox" ref={register({ required: false })}/>
                        <label className="form-check-label" htmlFor="checkbox_modifiers">Modifiers</label>
                      </div>
                    </div> */}
                    </div>
                  </div>

                  <br />
                  <h5>Select Descriptive Features</h5>
                  <div className="form-group">
                    <div className="col-md-12 mb-12 row">
                      {features && features.length > 0 ? features.map((item, index) => {
                        if (item.descriptive_feature)
                          return (
                            <div className="form-check">
                              <div className="checkbox p-0">
                                <input className="form-check-input" name="multifeature" id={"checkbox_multifeature" + index} type="checkbox" value={item.title} ref={register({ required: false })} />
                                <label className="form-check-label" htmlFor={"checkbox_multifeature" + index}>{item.title}</label>
                              </div>
                            </div>
                          )
                      }) : null}
                    </div>
                  </div>
                  <hr />
                  <div className="">
                    {!loadingbtn ?
                      <>
                        <Link to={`${process.env.PUBLIC_URL}/subscriptions`}>
                          <Button color="default" type="button">Cancel</Button>
                        </Link>
                        <Button color="primary" className="pull-right" type="submit" style={{ marginLeft: "2rem" }}>Save</Button>
                      </>
                      :
                      <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </form>
        }
      </div>
    </>
  );
}

export default TooltipForm;